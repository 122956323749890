/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from "prop-types";
import * as React from "react"
// Components
import {
  Footer,
  Header,
  Seo,
} from "../components/";

// Styles
import "../styles/global.css";
import "../styles/core/fonts.css";
import "../styles/core/containers.css";
import "../styles/core/typography.css";
import "../styles/core/buttons.css";
import "../styles/core/form.css";
import "../styles/core/navigation.css";
import "../styles/components/carousel.css";
import "../styles/components/iframe.css";
import "../styles/core/lists.css";

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Seo {...this.props.seo} />
        <Header />
        <main role="main">{children}</main>
        <Footer />
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.object,
};

export default Layout;
