// import PropTypes from "prop-types";
import React from "react";
import Layout from "../layout";

// import {
//   RichText,
// } from "../../../components/";

class TermsAndConditions extends React.Component {
  render(){
    return (
      <Layout
        seo={{
          path: "/terms-and-conditions",
          title: "Terms and Conditions",
        }}
      >
      <div className="px-6 py-12">
        <div className="cContainer">
          <div className="py-4">
            <h1>ANJA Life LLC Terms and Conditions</h1>
            <h2>Last Updated: Nov 16th, 2023</h2>
          </div>
          {/* <RichText json={JSON.parse(data.richtext.raw)} /> */}
          <div className="rte">
            <h4>
              TERMS AND CONDITIONS
            </h4>
            <p>Welcome to ANJA's Website ("The Website"). These terms and conditions ("Terms") govern your access to and use of the Website. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the Website.</p>
            <p><span className="text-purple">1.</span> Age Restrictions You must be of legal age to access and use the Website. By accessing and using the Website, you represent and warrant that you are at least 21 years old.</p>
            <p><span className="text-purple">2.</span> Compliance with Applicable Laws You agree to comply with all applicable laws, regulations, and guidelines regarding the purchase, possession, and use of cannabis products in your jurisdiction.</p>
            <p><span className="text-purple">3.</span> Website Content All content provided on the Website is for informational purposes only and should not be considered medical, legal, or professional advice. We do not endorse or guarantee the accuracy, completeness, or usefulness of any content provided on the Website.</p>
            <p><span className="text-purple">4.</span> Product Information The Website may provide information about various cannabis products. Such information is provided for general informational purposes only and does not constitute an offer to sell or promote the use of any specific product. We make no representations or warranties regarding the safety, effectiveness, or suitability of any product described on the Website.</p>
            <p><span className="text-purple">5.</span> Ordering and Purchasing If you choose to purchase products through the Website, you acknowledge and agree to comply with our separate terms of sale, which govern the purchase process, payment, shipping, returns, and any other related matters.</p>
            <p><span className="text-purple">6.</span> Intellectual Property All intellectual property rights, including trademarks, logos, designs, and copyrighted materials, displayed on the Website are the property of their respective owners. You may not use, reproduce, modify, or distribute any of the intellectual property without prior written consent from the respective owner.</p>
            <p><span className="text-purple">7.</span> Third-Party Links The Website may contain links to third-party websites or resources. We provide these links for your convenience, but we do not endorse or assume any responsibility for the content, products, or services provided by third parties. You acknowledge and agree that your access and use of any third-party website is at your own risk.</p>
            <p><span className="text-purple">8.</span> Limitation of Liability To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of the Website, including but not limited to any errors or omissions in the content, or any loss or damage incurred as a result of the use of any products or services obtained through the Website.</p>
            <p><span className="text-purple">9.</span> Indemnification You agree to indemnify and hold us harmless from and against any claims, damages, liabilities, costs, and expenses arising out of or in connection with your access to or use of the Website, your violation of these Terms, or your violation of any applicable laws or regulations.</p>
            <p><span className="text-purple">10.</span> Modifications and Termination We reserve the right to modify, suspend, or terminate the Website or these Terms at any time, without prior notice and for any reason. We may also impose limits on certain features or restrict access to parts of the Website without liability.</p>
            <p><span className="text-purple">11.</span> Governing Law and Jurisdiction These Terms shall be governed by and construed in accordance with the laws of New Jersey. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in New Jersey.</p>
          </div>
        </div>
      </div>
      </Layout>
  )
  }
  
  
}

export default TermsAndConditions
